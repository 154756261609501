import React, { useState, useEffect } from 'react';
import {
    Modal,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ReactSelect from 'react-select';
import dayjs from 'dayjs';
import axios from 'axios';

const repeatOptions = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' }
];

export default function SubshiftEditModal({
    open,
    onClose,
    shiftId,
    onUpdateSuccess,
    toast
}) {
    // State for form fields
    const [shiftName, setShiftName] = useState('');
    const [shiftType, setShiftType] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [workingHours, setWorkingHours] = useState(0);
    const [cutOffLeaveType, setCutOffLeaveType] = useState('');
    const [cutOffTime, setCutOffTime] = useState(null);
    const [effectiveFrom, setEffectiveFrom] = useState(null);
    const [priority, setPriority] = useState(1);
    const [repeatDays, setRepeatDays] = useState(false);
    const [selectedRepeatDays, setSelectedRepeatDays] = useState([]);
    const [specificDate, setSpecificDate] = useState(null);

    // Fetch initial data when modal opens
    useEffect(() => {
        if (open && shiftId) {
            const fetchShiftDetails = async () => {
                const token = localStorage.getItem("accessToken");
                try {
                    const response = await axios.get(
                        `https://hrmsbackendversiontwo.prosevo.com/api/employee/shiftlines/${shiftId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    const data = response.data;

                    // Populate form fields
                    setShiftName(data.shift_name || '');
                    setShiftType(data.shift_type || '');
                    setStartTime(data.start_time ? dayjs(data.start_time, 'HH:mm') : null);
                    setEndTime(data.end_time ? dayjs(data.end_time, 'HH:mm') : null);
                    setWorkingHours(data.working_hours || 0);
                    setCutOffLeaveType(data.leave_type || '');
                    setCutOffTime(data.cut_off_time ? dayjs(data.cut_off_time, 'HH:mm') : null);
                    setEffectiveFrom(data.effective_from ? dayjs(data.effective_from) : null);
                    setPriority(data.priority || 1);

                    // Handle repeat days logic
                    if (data.repeat_days && data.repeat_days.length > 0) {
                        setRepeatDays(true);
                        setSelectedRepeatDays(
                            repeatOptions.filter(option =>
                                data.repeat_days.includes(option.value)
                            )
                        );
                    } else {
                        setRepeatDays(false);
                        setSpecificDate(data.specific_date ? dayjs(data.specific_date) : null);
                    }
                } catch (error) {
                    console.error("Error fetching shift details:", error);
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Failed to fetch shift details. Please try again.',
                        life: 3000,
                    });
                }
            };

            fetchShiftDetails();
        }
    }, [open, shiftId]);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("accessToken");

        // Prepare data for submission
        const submitData = {
            shift_name: shiftName,
            shift_type: shiftType,
            start_time: startTime ? startTime.format('HH:mm') : null,
            end_time: endTime ? endTime.format('HH:mm') : null,
            working_hours: workingHours,
            leave_type: cutOffLeaveType,
            cut_off_time: cutOffTime ? cutOffTime.format('HH:mm') : null,
            effective_from: effectiveFrom ? effectiveFrom.format('YYYY-MM-DD') : null,
            priority: priority,
            repeat_days: repeatDays ? selectedRepeatDays.map(day => day.value) : [],
            specific_date: !repeatDays && specificDate ? specificDate.format('YYYY-MM-DD') : null
        };

        try {
            const response = await axios.put(
                `https://hrmsbackendversiontwo.prosevo.com/api/employee/shiftlines/${shiftId}`,
                submitData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                }
            );

            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Shift updated successfully!',
                life: 3000
            });

            // Call the success callback to potentially refresh data
            onUpdateSuccess && onUpdateSuccess();

            // Close the modal
            onClose();
        } catch (error) {
            console.error("Error updating shift:", error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to update shift. Please try again.',
                life: 3000
            });
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="edit-shift-modal"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiPaper-root': {
                    width: '90%',
                    maxWidth: '600px',
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    padding: '20px',
                    backgroundColor: 'white',
                    borderRadius: '8px'
                }
            }}
        >
            <form onSubmit={handleSubmit} style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px',
                justifyContent: 'space-between'
            }}>
                <TextField
                    label="Enter Shift Name"
                    variant="outlined"
                    fullWidth
                    style={{ maxWidth: "48%" }}
                    value={shiftName}
                    onChange={(e) => setShiftName(e.target.value)}
                    required
                />

                <FormControl fullWidth style={{ maxWidth: "48%" }}>
                    <InputLabel>Shift Type</InputLabel>
                    <Select
                        value={shiftType}
                        onChange={(e) => setShiftType(e.target.value)}
                        required
                    >
                        <MenuItem value="Flexible">Flexible</MenuItem>
                        <MenuItem value="Fixed">Fixed</MenuItem>
                    </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ maxWidth: "48%", flex: "1 1 48%" }}>
                        <TimePicker
                            label="Start Time"
                            value={startTime}
                            onChange={(newValue) => setStartTime(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            required
                        />
                    </div>
                    <div style={{ maxWidth: "48%", flex: "1 1 48%" }}>
                        <TimePicker
                            label="End Time"
                            value={endTime}
                            onChange={(newValue) => setEndTime(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            required
                        />
                    </div>
                    <div style={{ maxWidth: "48%", flex: "1 1 48%" }}>
                        <TextField
                            label="Working Hours"
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={workingHours}
                            onChange={(e) => {
                                const value = Number(e.target.value);
                                if (value >= 0) setWorkingHours(value);
                            }}
                            inputProps={{ min: 0 }}
                            required
                        />
                    </div>

                    <FormControl fullWidth style={{ maxWidth: "48%" }}>
                        <InputLabel>Cut-Off Leave Type</InputLabel>
                        <Select
                            value={cutOffLeaveType}
                            onChange={(e) => setCutOffLeaveType(e.target.value)}
                            required
                        >
                            <MenuItem value="Quarter">Quarter</MenuItem>
                            <MenuItem value="Half">Half</MenuItem>
                        </Select>
                    </FormControl>

                    <div style={{ maxWidth: "48%", flex: "1 1 48%" }}>
                        <TimePicker
                            label="Cut-Off Time"
                            value={cutOffTime}
                            onChange={(newValue) => setCutOffTime(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    disabled={shiftType !== "Flexible"}
                                />
                            )}
                            required={shiftType === "Flexible"}
                        />
                    </div>

                    <div style={{ maxWidth: "48%", flex: "1 1 48%" }}>
                        <DatePicker
                            label="Effective From"
                            value={effectiveFrom}
                            onChange={(newValue) => setEffectiveFrom(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            required
                        />
                    </div>
                </LocalizationProvider>

                <TextField
                    label="Priority"
                    type="number"
                    variant="outlined"
                    fullWidth
                    style={{ maxWidth: "48%" }}
                    value={priority}
                    onChange={(e) => setPriority(Number(e.target.value) || 1)}
                    inputProps={{ min: 1 }}
                    required
                />

                {repeatDays ? (
                    <div style={{ width: '48%' }}>
                        <ReactSelect
                            options={repeatOptions}
                            isMulti
                            value={selectedRepeatDays}
                            onChange={(selected) => setSelectedRepeatDays(selected)}
                            placeholder="Select repeat days"
                            required
                        />
                    </div>
                ) : (
                    <div style={{ width: '48%' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Specific Date"
                                value={specificDate}
                                onChange={(newValue) => setSpecificDate(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                required
                            />
                        </LocalizationProvider>
                    </div>
                )}

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={repeatDays}
                            onChange={(e) => setRepeatDays(e.target.checked)}
                        />
                    }
                    label="Repeat Days"
                />

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                    >
                        Update Shift
                    </button>
                </div>
            </form>
        </Modal>
    );
}