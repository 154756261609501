import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Typography
} from '@mui/material';
import { LocalizationProvider, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';

export default function ShiftEditModal({
    open,
    onClose,
    shift,
    shiftId,
    onUpdateSuccess
}) {
    const [shiftName, setShiftName] = useState('');
    const [shiftType, setShiftType] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [workingHours, setWorkingHours] = useState('');
    const [cutOffLeaveType, setCutOffLeaveType] = useState('');
    const [cutOffTime, setCutOffTime] = useState(null);
    const [effectiveFrom, setEffectiveFrom] = useState(null);

    useEffect(() => {
        if (shift && open) {
            setShiftName(shift.shift_name || '');
            setShiftType(shift.shift_type || '');

            // Explicitly handle time fields with more robust parsing
            setStartTime(shift.start_time ? dayjs(`1970-01-01T${shift.start_time}`) : null);
            setEndTime(shift.end_time ? dayjs(`1970-01-01T${shift.end_time}`) : null);

            // Parse working hours correctly
            if (shift.working_hours) {
                const [hours] = shift.working_hours.split(':');
                setWorkingHours(parseInt(hours));
            } else {
                setWorkingHours('');
            }

            setCutOffLeaveType(shift.leave_type || '');
            setCutOffTime(shift.cut_off_time ? dayjs(`1970-01-01T${shift.cut_off_time}`) : null);

            // Correctly handle effective_from field
            setEffectiveFrom(shift.effective_from ? dayjs(shift.effective_from) : null);
        }
    }, [shift, open]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('accessToken');

        const payload = {
            shift: {
                shift_name: shiftName,
                shift_type: shiftType,
                start_time: startTime ? startTime.format('HH:mm:ss') : null,
                end_time: endTime ? endTime.format('HH:mm:ss') : null,
                working_hours: workingHours ? `${workingHours}:00:00` : null,
                leave_type: cutOffLeaveType,
                // Only include cut_off_time if shift_type is Flexible
                ...(shiftType === 'Flexible' ? {
                    cut_off_time: cutOffTime ? cutOffTime.format('HH:mm:ss') : null
                } : { cut_off_time: null }),
                effective_from: effectiveFrom ? effectiveFrom.format('YYYY-MM-DD') : null
            }
        };

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/api/employee/shifts/${shiftId}`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            onUpdateSuccess(response.data);
            onClose();
        } catch (error) {
            console.error('Error updating shift:', error);
            // You might want to add error handling toast or modal here
        }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 2
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="shift-edit-modal-title"
        >
            <Box sx={style} component="form" onSubmit={handleSubmit}>
                <Typography id="shift-edit-modal-title" variant="h6" component="h2">
                    Edit Shift Details
                </Typography>

                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    <TextField
                        label="Enter Shift Name"
                        variant="outlined"
                        fullWidth
                        sx={{ flex: '1 1 100%', maxWidth: '48%' }}
                        value={shiftName}
                        onChange={(e) => setShiftName(e.target.value)}
                        required
                    />

                    <FormControl fullWidth sx={{ maxWidth: '48%' }}>
                        <InputLabel>Shift Type</InputLabel>
                        <Select
                            value={shiftType}
                            label="Shift Type"
                            onChange={(e) => setShiftType(e.target.value)}
                            required
                        >
                            <MenuItem value="Flexible">Flexible</MenuItem>
                            <MenuItem value="Fixed">Fixed</MenuItem>
                        </Select>
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Start Time"
                            value={startTime}
                            onChange={(newValue) => setStartTime(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth sx={{ maxWidth: '48%' }} />}
                        />
                        <TimePicker
                            label="End Time"
                            value={endTime}
                            onChange={(newValue) => setEndTime(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth sx={{ maxWidth: '48%' }} />}
                        />

                        <TextField
                            label="Working Hours"
                            variant="outlined"
                            type="number"
                            fullWidth
                            sx={{ maxWidth: '48%' }}
                            value={workingHours}
                            onChange={(e) => {
                                const value = Number(e.target.value);
                                if (value >= 0) setWorkingHours(value);
                            }}
                            inputProps={{ min: 0 }}
                        />

                        <FormControl fullWidth sx={{ maxWidth: '48%' }}>
                            <InputLabel>Cut-Off Leave Type</InputLabel>
                            <Select
                                value={cutOffLeaveType}
                                label="Cut-Off Leave Type"
                                onChange={(e) => setCutOffLeaveType(e.target.value)}
                            >
                                <MenuItem value="Quarter">Quarter</MenuItem>
                                <MenuItem value="Half">Half</MenuItem>
                            </Select>
                        </FormControl>

                        <TimePicker
                            label="Cut-Off Time"
                            value={cutOffTime}
                            onChange={(newValue) => setCutOffTime(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    sx={{ maxWidth: '48%' }}
                                    disabled={shiftType !== "Flexible"}
                                />
                            )}
                        />

                        <DatePicker
                            label="Effective From"
                            value={effectiveFrom}
                            onChange={(newValue) => setEffectiveFrom(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth sx={{ maxWidth: '48%' }} />}
                        />
                    </LocalizationProvider>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    <Button onClick={onClose} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Update Shift
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}