import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { IconCheck, IconPlus, IconTrash } from '@tabler/icons-react';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactSelect from 'react-select';

export default function SubShiftAdd() {
    const [shiftName, setShiftName] = useState("");
    const [shiftType, setShiftType] = useState("");
    const [priority, setPriority] = useState(1);
    const [cutOffTime, setCutOffTime] = useState(dayjs());
    const [cutOffLeaveType, setCutOffLeaveType] = useState("");
    const [startTime, setStartTime] = useState(dayjs());
    const [endTime, setEndTime] = useState(dayjs());
    const [workingHours, setWorkingHours] = useState(1);
    const [effectiveFrom, setEffectiveFrom] = useState(dayjs());
    const [breaks, setBreaks] = useState([]);
    const [repeatDays, setRepeatDays] = useState(false);
    const [selectedRepeatDays, setSelectedRepeatDays] = useState([]);
    const [specificDate, setSpecificDate] = useState(dayjs());

    const [newBreak, setNewBreak] = useState({
        name: "",
        type: "",
        break_duration: 1,
        startTime: dayjs(),
        endTime: dayjs(),
    });

    const repeatOptions = [
        { value: "Monday", label: "Monday" },
        { value: "Tuesday", label: "Tuesday" },
        { value: "Wednesday", label: "Wednesday" },
        { value: "Thursday", label: "Thursday" },
        { value: "Friday", label: "Friday" },
        { value: "Saturday", label: "Saturday" },
        { value: "Sunday", label: "Sunday" }
    ];

    const handleAddBreak = () => {
        if (newBreak.name && newBreak.type) {
            if (newBreak.type === "Flexible" && newBreak.break_duration <= 0) {
                toast.error("Break Duration must be a positive value.");
                return;
            }
            setBreaks([...breaks, newBreak]);
            setNewBreak({
                name: "",
                type: "",
                break_duration: 1,
                startTime: dayjs(),
                endTime: dayjs(),
            });
            toast.success("Break added successfully!");
            document.getElementById("closeBreakModal").click(); // Close modal
        } else {
            toast.error("Please complete the break details before adding.");
        }
    };

    const formatDuration = (hours) =>
        dayjs.duration(hours, "hours").format("HH:mm:ss");

    const handleRemoveBreak = (index) => {
        setBreaks(breaks.filter((_, i) => i !== index));
    };

    const calculateDuration = (start, end) => {
        const diff = dayjs.duration(end.diff(start));
        return `${diff.hours().toString().padStart(2, "0")}:${diff
            .minutes()
            .toString()
            .padStart(2, "0")}:${diff.seconds().toString().padStart(2, "0")}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation logic for required fields
        if (!shiftName.trim()) {
            toast.error("Shift Name is required.");
            return;
        }

        if (!shiftType) {
            toast.error("Shift Type is required.");
            return;
        }

        if (!startTime || !endTime) {
            toast.error("Start Time and End Time are required.");
            return;
        }

        if (dayjs(endTime).isBefore(dayjs(startTime))) {
            toast.error("End Time must be after Start Time.");
            return;
        }

        if (!workingHours || workingHours <= 0) {
            toast.error("Working Hours must be greater than 0.");
            return;
        }

        if (shiftType === "Flexible" && !cutOffTime) {
            toast.error("Cut-Off Time is required for Flexible shifts.");
            return;
        }

        if (!cutOffLeaveType) {
            toast.error("Cut-Off Leave Type is required.");
            return;
        }

        if (!effectiveFrom) {
            toast.error("Effective From date is required.");
            return;
        }

        // Prepare the data object with additional fields
        const data = {
            shift: {
                shift_name: shiftName,
                shift_type: shiftType,
                start_time: startTime.format("HH:mm:ss"),
                end_time: endTime.format("HH:mm:ss"),
                working_hours: formatDuration(workingHours),
                cut_off_time: shiftType === "Flexible" ? cutOffTime.format("HH:mm:ss") : null,
                leave_type: cutOffLeaveType,
                effective_from: effectiveFrom.format("YYYY-MM-DD"),
                priority: priority,
                repeat_days: repeatDays ? selectedRepeatDays.map(day => day.value) : [],
                specific_date: !repeatDays ? specificDate.format("YYYY-MM-DD") : null,
                shift: 2
            },
            breaks: breaks.map((b) => {
                // For Flexible breaks, use the break_duration
                if (b.type === "Flexible") {
                    return {
                        break_name: b.name,
                        break_type: b.type,
                        break_duration: formatDuration(b.break_duration),
                        start_time: null,
                        end_time: null,
                    };
                }

                // For Fixed breaks, calculate duration and include start/end times
                return {
                    break_name: b.name,
                    break_type: b.type,
                    break_duration: calculateDuration(b.startTime, b.endTime),
                    start_time: b.startTime.format("HH:mm:ss"),
                    end_time: b.endTime.format("HH:mm:ss"),
                };
            }),
        };

        // Submit the data to the server
        try {
            const token = localStorage.getItem("accessToken");
            if (!token) {
                toast.error("Authentication token not found. Please log in.");
                return;
            }

            console.log("Sending data:", JSON.stringify(data)); // Detailed logging

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/employee/create-shiftline/`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log("API Response:", response.data); // Log the API response
            
            toast.success("New Sub Shift Added Successfully!");
            
            // Reset form after successful submission
            resetForm();
        } catch (error) {
            console.error("API Submission Error:", error.response ? error.response.data : error);
            toast.error(`Failed to save shift: ${error.response?.data?.message || error.message}`);
        }
    };

    const resetForm = () => {
        setShiftName("");
        setShiftType("");
        setPriority(1);
        setCutOffTime(dayjs());
        setCutOffLeaveType("");
        setStartTime(dayjs());
        setEndTime(dayjs());
        setWorkingHours(1);
        setEffectiveFrom(dayjs());
        setBreaks([]);
        setRepeatDays(false);
        setSelectedRepeatDays([]);
        setSpecificDate(dayjs());
    };

    return (
        <div className="shift-form p-3">
            <h4 className='mb-3'>Subshift Add</h4>
            <form className="d-flex flex-wrap gap-3" onSubmit={handleSubmit}>
                <TextField
                    label="Enter Shift Name"
                    variant="outlined"
                    fullWidth
                    style={{ maxWidth: "48%" }}
                    value={shiftName}
                    onChange={(e) => setShiftName(e.target.value)}
                />

                <FormControl fullWidth style={{ maxWidth: "48%" }}>
                    <InputLabel>Shift Type</InputLabel>
                    <Select
                        value={shiftType}
                        onChange={(e) => setShiftType(e.target.value)}
                    >
                        <MenuItem value="Flexible">Flexible</MenuItem>
                        <MenuItem value="Fixed">Fixed</MenuItem>
                    </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ maxWidth: "48%", flex: "1 1 48%" }}>
                        <TimePicker
                            label="Start Time"
                            value={startTime}
                            onChange={(newValue) => setStartTime(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </div>
                    <div style={{ maxWidth: "48%", flex: "1 1 48%" }}>
                        <TimePicker
                            label="End Time"
                            value={endTime}
                            onChange={(newValue) => setEndTime(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </div>
                    <div style={{ maxWidth: "48%", flex: "1 1 48%" }}>
                        <TextField
                            label="Working Hours"
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={workingHours}
                            onChange={(e) => {
                                const value = Number(e.target.value);
                                if (value >= 0) setWorkingHours(value);
                            }}
                            inputProps={{ min: 0 }}
                        />
                    </div>

                    <FormControl fullWidth style={{ maxWidth: "48%" }}>
                        <InputLabel>Cut-Off Leave Type</InputLabel>
                        <Select
                            value={cutOffLeaveType}
                            onChange={(e) => setCutOffLeaveType(e.target.value)}
                        >
                            <MenuItem value="Quarter">Quarter</MenuItem>
                            <MenuItem value="Half">Half</MenuItem>
                        </Select>
                    </FormControl>

                    <div style={{ maxWidth: "48%", flex: "1 1 48%" }}>
                        <TimePicker
                            label="Cut-Off Time"
                            value={cutOffTime}
                            onChange={(newValue) => setCutOffTime(newValue)}
                            renderInput={(params) => (
                                <TextField {...params} fullWidth disabled={shiftType !== "Flexible"} />
                            )}
                        />
                    </div>

                    <div style={{ maxWidth: "48%", flex: "1 1 48%" }}>
                        <DatePicker
                            label="Effective From"
                            value={effectiveFrom}
                            onChange={(newValue) => setEffectiveFrom(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </div>
                </LocalizationProvider>
                <TextField
                    label="Priority"
                    type="number"
                    variant="outlined"
                    fullWidth
                    style={{ maxWidth: "48%" }}
                    value={priority}
                    onChange={(e) => setPriority(Number(e.target.value) || 1)}
                    inputProps={{ min: 1 }}
                />
                
                {repeatDays ? (
                    <div style={{width:'48%'}}>
                        <ReactSelect
                            options={repeatOptions}
                            isMulti
                            value={selectedRepeatDays}
                            onChange={(selected) => setSelectedRepeatDays(selected)}
                            placeholder="Select repeat days"
                        />
                    </div>
                ) : (
                    <div style={{width:'48%'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Specific Date"
                            value={specificDate}
                            onChange={(newValue) => setSpecificDate(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                    </div>
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={repeatDays}
                            onChange={(e) => setRepeatDays(e.target.checked)}
                        />
                    }
                    label="Repeat Days"
                />

                <div className="break-section w-100 d-flex flex-column justify-content-end">
                    <button
                        className="btn btn-outline-success"
                        data-bs-toggle="modal"
                        data-bs-target="#breakModal"
                        type="button"
                    >
                        Add Break <IconPlus stroke={2} />
                    </button>

                    {/* Breaks Preview */}
                    <div className="break-preview mt-3">
                        {breaks.map((b, index) => (
                            <div
                                key={index}
                                className="break-item p-3 d-flex flex-column border rounded shadow-sm"
                            >
                                <div className="d-flex justify-content-between">
                                    <strong>{b.name} - {b.type}</strong>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        size="small"
                                        onClick={() => handleRemoveBreak(index)}
                                    >
                                        <IconTrash stroke={1} />
                                    </Button>
                                </div>
                                <p className="mb-0">
                                    <span>Duration:</span>{" "}
                                    {b.type === "Flexible"
                                        ? formatDuration(b.break_duration)
                                        : calculateDuration(b.startTime, b.endTime)}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-end">
                    <button type="submit" className="btn btn-primary">
                        Save Shift <IconCheck stroke={2} />
                    </button>
                </div>
            </form>

            {/* Bootstrap Modal */}
            <div
                className="modal fade"
                id="breakModal"
                tabIndex="-1"
                aria-labelledby="breakModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="breakModalLabel">
                                Add Break
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body d-flex flex-column gap-3">
                            <TextField
                                label="Break Name"
                                variant="outlined"
                                fullWidth
                                value={newBreak.name}
                                onChange={(e) =>
                                    setNewBreak({ ...newBreak, name: e.target.value })
                                }
                            />
                            <FormControl fullWidth>
                                <InputLabel>Break Type</InputLabel>
                                <Select
                                    value={newBreak.type}
                                    onChange={(e) => setNewBreak({ ...newBreak, type: e.target.value })}
                                >
                                    <MenuItem value="Fixed">Fixed</MenuItem>
                                    <MenuItem value="Flexible">Flexible</MenuItem>
                                </Select>
                            </FormControl>
                            {newBreak.type === "Flexible" ? (
                                <TextField
                                    label="Break Duration (Hours)"
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    value={newBreak.break_duration}
                                    onChange={(e) =>
                                        setNewBreak({
                                            ...newBreak,
                                            break_duration: Number(e.target.value),
                                        })
                                    }
                                />
                            ) : (
                                <>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Start Time"
                                            value={newBreak.startTime}
                                            onChange={(newValue) =>
                                                setNewBreak({ ...newBreak, startTime: newValue })
                                            }
                                        />
                                        <TimePicker
                                            label="End Time"
                                            value={newBreak.endTime}
                                            onChange={(newValue) =>
                                                setNewBreak({ ...newBreak, endTime: newValue })
                                            }
                                        />
                                    </LocalizationProvider>
                                    <TextField
                                        label="Duration (Read Only)"
                                        variant="outlined"
                                        fullWidth
                                        value={calculateDuration(newBreak.startTime, newBreak.endTime)}
                                        InputProps={{ readOnly: true }}
                                    />
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                id="closeBreakModal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleAddBreak}
                            >
                                Save Break
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
