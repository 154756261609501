import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const BreakModal = ({
    showModal,
    onClose,
    onSubmit,
    initialBreak = null,
    isEditMode = false
}) => {
    const [newBreak, setNewBreak] = useState({
        name: '',
        type: '',
        startTime: dayjs(),
        endTime: dayjs(),
        break_duration: 0,
    });

    // Helper function to convert numeric duration to HH:mm:ss format
    const convertToHHMMSS = (durationInHours) => {
        const totalMinutes = Math.round(durationInHours * 60);
        const hours = Math.floor(totalMinutes / 60).toString().padStart(2, '0');
        const minutes = (totalMinutes % 60).toString().padStart(2, '0');
        const seconds = '00';
        return `${hours}:${minutes}:${seconds}`;
      };
      

    // Convert HH:mm:ss format to numeric hours
    const convertToNumericDuration = (duration) => {
        const [hours, minutes] = duration.split(':');
        return parseFloat(hours) + parseFloat(minutes) / 60;
    };

    // Set the state when modal is shown or updated for editing
    useEffect(() => {
        if (isEditMode && initialBreak) {
            const durationInHours = convertToNumericDuration(initialBreak?.duration || '00:00:00');
            setNewBreak({
                name: initialBreak?.break_name || '',
                type: initialBreak?.break_type || '',
                startTime: initialBreak?.start_time ? dayjs(initialBreak.start_time, 'HH:mm:ss') : dayjs(),
                endTime: initialBreak?.end_time ? dayjs(initialBreak.end_time, 'HH:mm:ss') : dayjs(),
                break_duration: durationInHours,
            });
        } else if (!showModal) {
            setNewBreak({
                name: '',
                type: '',
                startTime: dayjs(),
                endTime: dayjs(),
                break_duration: 0,
            });
        }
    }, [showModal, isEditMode, initialBreak]);

    // Calculate duration in HH:mm:ss format from startTime and endTime
    const calculateDuration = (start, end) => {
        if (start && end) {
          const diff = Math.abs(end.diff(start)); 
          const hours = Math.floor(diff / (1000 * 60 * 60)).toString().padStart(2, '0');
          const minutes = Math.floor((diff / (1000 * 60)) % 60).toString().padStart(2, '0');
          const seconds = Math.floor((diff / 1000) % 60).toString().padStart(2, '0');
          return `${hours}:${minutes}:${seconds}`;
        }
        return '00:00:00';
      };
      

    const handleSubmit = () => {
        if (!newBreak.name || !newBreak.type) {
            alert('Break name and type are required.');
            return;
        }

        if (newBreak.type === 'Fixed' && (!newBreak.startTime || !newBreak.endTime)) {
            alert('Start and end times are required for fixed breaks.');
            return;
        }

        if (newBreak.type === 'Flexible' && newBreak.break_duration <= 0) {
            alert('Break duration must be greater than 0.');
            return;
        }

        const formattedDuration = newBreak.type === 'Flexible'
            ? convertToHHMMSS(newBreak.break_duration) 
            : calculateDuration(newBreak.startTime, newBreak.endTime); 
        const processedBreakData = {
            break_name: newBreak.name,
            break_type: newBreak.type,
            start_time: newBreak.type === "Fixed" ? newBreak.startTime.format("HH:mm:ss") : null,
            end_time: newBreak.type === "Fixed" ? newBreak.endTime.format("HH:mm:ss") : null,
            break_duration: formattedDuration,
        };

        onSubmit(processedBreakData);
    };


    return (
        <Modal
            open={showModal}
            onClose={onClose}
            aria-labelledby="break-modal-title"
        >
            <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: '10px'
            }}>
                <h5 id="break-modal-title">
                    {isEditMode ? 'Edit Break' : 'Add Break'}
                </h5>
                <div className="modal-body d-flex flex-column px-0 gap-3">
                    <TextField
                        label="Break Name"
                        variant="outlined"
                        fullWidth
                        value={newBreak.name}
                        onChange={(e) => setNewBreak({ ...newBreak, name: e.target.value })}
                    />
                    <FormControl fullWidth>
                        <InputLabel>Break Type</InputLabel>
                        <Select
                            value={newBreak.type}
                            onChange={(e) => {
                                const selectedType = e.target.value;
                                setNewBreak({
                                    ...newBreak,
                                    type: selectedType,
                                    startTime: selectedType === "Flexible" ? null : dayjs(),
                                    endTime: selectedType === "Flexible" ? null : dayjs(),
                                    break_duration: selectedType === "Flexible" ? 0 : newBreak.break_duration,
                                });
                            }}
                        >
                            <MenuItem value="Fixed">Fixed</MenuItem>
                            <MenuItem value="Flexible">Flexible</MenuItem>
                        </Select>
                    </FormControl>

                    {newBreak.type === 'Flexible' ? (
                        <TextField
                            label="Break Duration (Hours)"
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={newBreak.break_duration}
                            onChange={(e) =>
                                setNewBreak({
                                    ...newBreak,
                                    break_duration: Number(e.target.value),
                                })
                            }
                        />
                    ) : (
                        <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    label="Start Time"
                                    value={newBreak.startTime}
                                    onChange={(newValue) =>
                                        setNewBreak({ ...newBreak, startTime: newValue })
                                    }
                                />
                                <TimePicker
                                    label="End Time"
                                    value={newBreak.endTime}
                                    onChange={(newValue) =>
                                        setNewBreak({ ...newBreak, endTime: newValue })
                                    }
                                />
                            </LocalizationProvider>
                            <TextField
                                label="Duration (Read Only)"
                                variant="outlined"
                                fullWidth
                                value={calculateDuration(newBreak.startTime, newBreak.endTime)}
                                InputProps={{ readOnly: true }}
                            />
                        </>
                    )}
                </div>
                <div className="modal-footer d-flex gap-2">
                    <button onClick={onClose} className="btn btn-secondary">Cancel</button>
                    <button onClick={handleSubmit} className="btn btn-primary">
                        {isEditMode ? 'Update Break' : 'Save Break'}
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default BreakModal;

