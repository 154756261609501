import React, { useState, useEffect } from 'react';
import Table from '../ogComponent/table';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import Spinner from '../ogComponent/spinner';

export default function UserTime() {
    const navigate = useNavigate();
    const { id } = useParams(); // Get ID from URL params
    const [startDate, setStartDate] = useState(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [employeeData, setEmployeeData] = useState(null);
    const [workingHours, setWorkingHours] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const header = [
        { name: 'si_no', label: '#', active: true },
        { name: 'date', label: 'Date', active: true },
        { name: 'day', label: 'Day', active: true },
        { name: 'working_hours', label: 'Hours', active: true },
    ];

    const fetchEmployeeData = async () => {
        setLoading(true);
        setError(null);
        
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                throw new Error('Authentication token not found');
            }

            const response = await axios.get(
                `https://hrmsbackend.prosevo.com/api/attendance/employee-worked-hours/${id}/`,
                {
                    params: {
                        start_date: startDate.format('YYYY-MM-DD'),
                        end_date: endDate.format('YYYY-MM-DD'),
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const { employee, worked_hours } = response.data;
            
            setEmployeeData(employee);
            
            // Format working hours data for the table
            const formattedHours = worked_hours.map((item, index) => ({
                si_no: index + 1,
                date: dayjs(item.date).format('DD/MM/YYYY'),
                day: item.day,
                working_hours: item.worked_hours
            }));

            setWorkingHours(formattedHours);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error.response?.data?.message || 'Failed to fetch employee data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEmployeeData();
    }, [id, startDate, endDate]);

    const handleDateChange = (newDate, type) => {
        if (type === 'start') {
            setStartDate(newDate);
        } else if (type === 'end') {
            setEndDate(newDate);
        }
    };

    const handleRowClick = (item) => {
        navigate(`/user/dayList/${id}`);
    };

    if (loading) {
        return (
           <Spinner />
        );
    }

    if (error) {
        return (
            <div className="AttendanceContainer w-100 h-100 px-3 px-lg-4 pt-3">
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            </div>
        );
    }

    return (
        <div className="AttendanceContainer w-100 h-100 px-3 px-lg-4 pt-3">
            <h3 className="pt-2">{employeeData?.name || 'Employee'}</h3>
            <p className='mb-0'>Branch : <span>{employeeData?.branch?.branch_name || 'N/A'}</span></p>
            <p className='mb-0'>Position : <span>{employeeData?.job_position?.job_title || 'N/A'}</span></p>
            <p className='mb-0'>Email : <span>{employeeData?.email || 'N/A'}</span></p>
            <p className='mb-0'>Phone : <span>{employeeData?.phone_number ? `+91 ${employeeData.phone_number}` : 'N/A'}</span></p>
            
            <div className='d-flex gap-3 py-3 justify-content-end'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Start Date"
                        className="MuidatePicker"
                        value={startDate}
                        onChange={(newValue) => handleDateChange(newValue, 'start')}
                        maxDate={endDate}
                    />
                    <DatePicker
                        label="End Date"
                        className="MuidatePicker"
                        value={endDate}
                        onChange={(newValue) => handleDateChange(newValue, 'end')}
                        minDate={startDate}
                    />
                </LocalizationProvider>
            </div>
            
            <Table 
                header={header} 
                product={workingHours} 
                onRowClick={handleRowClick}
                loading={loading} 
            />
        </div>
    );
}