import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { useNavigate, useParams } from 'react-router-dom';
import BreakModal from './breakModal';
import BreakDeleteConfirmationModal from './deleteConfirm';
import BreaksAccordion from './breakAccordion';
import SubshiftEditModal from './subShiftModal';

export default function Subshift() {
    const [shift, setShift] = useState(null);
    const [breaks, setBreaks] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isEditBreakMode, setIsEditBreakMode] = useState(false);
    const [selectedBreak, setSelectedBreak] = useState(null);
    const [showBreakModal, setShowBreakModal] = useState(false);
    const navigate = useNavigate();
    const toast = useRef(null);
    const { id } = useParams();

    const fetchShiftDetails = useCallback(async () => {
        const token = localStorage.getItem("accessToken");
        try {
            const response = await axios.get(
                `https://hrmsbackendversiontwo.prosevo.com/api/employee/shiftlines/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = response.data;
            setShift(data);
            setBreaks(data.shiftline_breaks || []);
        } catch (error) {
            console.error("Error fetching shift details:", error);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to fetch shift details. Please try again.',
                life: 3000,
            });
        }
    }, [id]);

    useEffect(() => {
        fetchShiftDetails();
    }, [fetchShiftDetails]);

    const handleEditClick = () => {
        setShowEditModal(true);
    };

    const handleAddBreak = async (breakData) => {
        const token = localStorage.getItem("accessToken");
        try {
            const response = await axios.post(`http://hrmsbackend.prosevo.com/api/employee/breaks/${id}`, breakData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Break added successfully!', life: 3000 });
            handleCloseBreakModal();
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error adding break. Please try again.', life: 3000 });
            console.error("Error adding break:", error);
        }
    };

    const handleOpenBreakModal = () => {
        console.log("clicked")
        setIsEditBreakMode(false);
        setSelectedBreak(null);
        setShowBreakModal(true);
    }

    const handleDeleteClick = () => {
        if (shift.employee_count > 0) {
            toast.current.show({
                severity: 'warn',
                summary: 'Cannot Delete Shift',
                detail: "This shift has associated employees. Please reassign them before deleting.",
                life: 3000,
            });
        } else {
            setOpenDeleteModal(true); // Correctly opens the modal
        }
    };

    const handleCloseBreakModal = () => {
        setShowBreakModal(false);
        setIsEditBreakMode(false);
        setSelectedBreak(null);
    };

    const handleEditBreak = (breakItem) => {
        setIsEditBreakMode(true);
        setSelectedBreak(breakItem);
        setShowBreakModal(true);
    };

    const handleDeleteBreak = async (breakId) => {
        const token = localStorage.getItem("accessToken");
        try {
            await axios.delete(`https://hrmsbackend.prosevo.com/api/common/employee/breaks/${breakId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Break deleted successfully!', life: 3000 });
            // Optionally, refresh breaks list or update state
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error deleting break. Please try again.', life: 3000 });
            console.error("Error deleting break:", error);
        }
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    };

    const handleConfirmDelete = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            await axios.delete(
                `${process.env.REACT_APP_API_URL}/api/employee/shiftlines/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setOpenDeleteModal(false);
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: "Position Deleted Successfully",
                life: 3000,
            });

            setTimeout(() => {
                navigate('/user/shifts/');
            }, 3000);
        } catch (error) {
            console.error('Error deleting position:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: "Failed to delete position",
                life: 3000,
            });
        }
    };

    return (
        <div className="singlePositionContainer px-4 pt-4 w-100">
            <Toast ref={toast} position="bottom-right" />
            {/* Header */}
            <h4>
                {shift?.shift_name || "N/A"} - {shift?.shift_type || "N/A"}
            </h4>

            {/* Shift Details */}
            <p className="mb-0 fw-bolder" style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>
                Working Hours:
                <span className="ms-2 fw-lighter" style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>
                    {shift?.working_hours || "N/A"}
                </span>
            </p>

            <p className="mb-0 fw-bolder" style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>
                Start Time:
                <span className="ms-2 fw-lighter" style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>
                    {shift?.start_time || "N/A"}
                </span>
            </p>

            <p className="mb-0 fw-bolder" style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>
                End Time:
                <span className="ms-2 fw-lighter" style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>
                    {shift?.end_time || "N/A"}
                </span>
            </p>

            <p className="mb-0 fw-bolder" style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>
                Cut Off Time:
                <span className="ms-2 fw-lighter" style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>
                    {shift?.cut_off_time || "N/A"}
                </span>
            </p>

            <p className="mb-0 fw-bolder" style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>
                Leave Type:
                <span className="ms-2 fw-lighter" style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>
                    {shift?.leave_type || "N/A"}
                </span>
            </p>

            <p className="mb-0 fw-bolder" style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>
                Priority:
                <span className="ms-2 fw-lighter" style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>
                    {shift?.priority || "N/A"}
                </span>
            </p>

            <div className="employeeTableContainer mt-4 w-100">
                <div className="d-flex justify-content-end mb-3 gap-2 gap-md-3">
                    <button className='btn btn-outline-success'
                        onClick={handleOpenBreakModal}
                    >
                        Add Break  <IconPlus stroke={2} />
                    </button>
                    <button
                        className="btn btn-outline-primary"
                        onClick={handleEditClick}
                        // data-bs-toggle="modal"
                        // data-bs-target="#staticBackdrop"
                    >
                        <IconEdit stroke={2} />
                    </button>
                    <button className="btn btn-outline-danger"
                        onClick={handleDeleteClick}
                    >
                        <IconTrash stroke={2} />
                    </button>
                </div>
            </div>

            {breaks.length > 0 ? (
                <BreaksAccordion
                    breaks={breaks}
                    onEditBreak={handleEditBreak}
                    onDeleteBreak={handleDeleteBreak}
                    header={"Breaks"}
                />
            ) : (
                ""
            )}

            {showEditModal && (
                <SubshiftEditModal
                    open={showEditModal}
                    onClose={() => setShowEditModal(false)}
                    shiftId={id}
                    onUpdateSuccess={() => {
                        fetchShiftDetails();
                    }}
                    toast={toast}
                />
            )}

            {showBreakModal && (
                <BreakModal
                    showModal={showBreakModal}
                    onClose={handleCloseBreakModal}
                    onSubmit={handleAddBreak}
                    initialBreak={selectedBreak}
                    isEditMode={isEditBreakMode}
                />
            )}

            <BreakDeleteConfirmationModal
                open={openDeleteModal}
                onClose={handleCloseDeleteModal}
                onConfirm={handleConfirmDelete}
                breakName="Shift"
            />
        </div>
    );
}
