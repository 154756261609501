import React from 'react';
import { Modal, Box } from '@mui/material';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const BreakDeleteConfirmationModal = ({ 
  open, 
  onClose, 
  onConfirm, 
  breakName 
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="break-delete-confirmation-modal"
    >
      <Box sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: '10px'
      }}>
        <div className="w-100 d-flex align-items-center flex-column">
          <WarningAmberIcon 
            sx={{ 
              color: "#FFA000", 
              fontSize: 60, 
              width: "100%" 
            }} 
          />
          <Box sx={{ 
            fontWeight: "bold", 
            fontSize: "clamp(13px, 1.4vw, 17px)", 
            mb: 2 
          }}>
            Confirm Deletion
          </Box>
          <Box sx={{
            color: "#555",
            mb: 2,
            fontSize: "clamp(12px, 1.2vw, 14px)",
            textAlign: "center",
            paddingX: "10px"
          }}>
            Are you sure you want to delete {breakName}?
            This action cannot be undone.
          </Box>
        </div>
        <div className="w-100 d-flex justify-content-between flex-row-reverse gap-3">
          <button
            className="btn btn-danger"
            onClick={onConfirm}
            style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
          >
            Delete
          </button>
          <button
            className="btn btn-secondary"
            onClick={onClose}
            style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
          >
            Cancel
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default BreakDeleteConfirmationModal;