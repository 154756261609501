import React, { useEffect, useRef, useState } from 'react'
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../ogComponent/table';
import Spinner from '../ogComponent/spinner';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import BreakModal from './breakModal';
import BreaksAccordion from './breakAccordion';
import BreakDeleteConfirmationModal from './deleteConfirm';
import ShiftEditModal from './shifteditModal';

export default function ShiftDetails() {
  const [shift, setShift] = useState(null);
  const [breaks, setBreaks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showBreakModal, setShowBreakModal] = useState(false);
  const [isEditBreakMode, setIsEditBreakMode] = useState(false);
  const [selectedBreak, setSelectedBreak] = useState(null);
  // const [showEditModal, setShowEditModal] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);
  const { id } = useParams();

  const sub_shifts = [
    {
      shift_line: {
        id: '1',
        shift_name: "Friday",
        shift_type: "Fixed",
        start_time: "09:00:00",
        end_time: "06:00:00",
        cut_off_time: null,
        leave_type: 'Quarter',
        working_hours: "08:00:00",
        effective_date: "2024-01-01",
        repeat_on: ["Monday", "Tuesday", "Friday"],
        priority: 1,
      },
      breaks: [
        {
          break_type: 'Fixed',
          break_name: 'Lunch Break',
          start_time: '15:00:00',
          end_time: '16:00:00',
          duration: '01:00:00'
        },
        {
          break_type: 'Flexible',
          break_name: 'Namaz',
          start_time: null,
          end_time: null,
          duration: '00:30:00'
        },
      ]
    },
    {
      shift_line: {
        id: '2',
        shift_name: "Second Saturday",
        shift_type: "Fixed",
        start_time: "09:00:00",
        end_time: "06:00:00",
        cut_off_time: null,
        leave_type: 'Quarter',
        working_hours: "08:00:00",
        effective_date: "2024-01-01",
        repeat_on: ["Monday", "Tuesday", "Friday"],
        priority: 1,
      },
      breaks: [
        {
          break_type: 'Fixed',
          break_name: 'Lunch Break',
          start_time: '15:00:00',
          end_time: '16:00:00',
          duration: '01:00:00'
        },
        {
          break_type: 'Flexible',
          break_name: 'Namaz',
          start_time: null,
          end_time: null,
          duration: '00:30:00'
        },
      ]
    }
  ]
  const header = [
    { name: "Si No", label: "#", active: true },
    { name: "name", label: "Name", active: true },
    { name: "branch", label: "Branch", active: true },
    { name: "department", label: "Department", active: true },
    { name: "contact_info", label: "Contact Info", active: true },
    { name: "Status", label: "Active", active: true },
  ];

  const handleUpdateSuccess = (updatedShift) => {
    // Update the shift state with the new data
    setShift(updatedShift);
    // Optionally show a success toast
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Shift updated successfully',
      life: 3000
    });
  };

  const handleOpenBreakModal = () => {
    setIsEditBreakMode(false);
    setSelectedBreak(null);
    setShowBreakModal(true);
  }

  const handleCloseBreakModal = () => {
    setShowBreakModal(false);
    setIsEditBreakMode(false);
    setSelectedBreak(null);
  };

  const handleAddBreak = async (breakData) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(`http://hrmsbackend.prosevo.com/api/employee/breaks/${id}`, breakData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Break added successfully!', life: 3000 });
      handleCloseBreakModal();
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error adding break. Please try again.', life: 3000 });
      console.error("Error adding break:", error);
    }
  };

  const handleEditBreak = (breakItem) => {
    setIsEditBreakMode(true);
    setSelectedBreak(breakItem);
    setShowBreakModal(true);
  };

  useEffect(() => {
    const fetchShiftDetails = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        setError('No token found');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/employee/shifts/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setShift(response.data);
        setBreaks(response.data.shift_breaks || []);
      } catch (error) {
        console.error("Error fetching shift details:", error);
        setError('Error fetching shift details');
      } finally {
        setLoading(false);
      }
    };

    fetchShiftDetails();
  }, [id]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const token = localStorage.getItem("accessToken");
      setLoading(true);

      try {
        let url = `https://hrmsbackend.prosevo.com/api/account/users-list/?shift=${id}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFilteredEmployees(response.data || []);
      } catch (error) {
        console.error("Error fetching employees:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [id]);

  const singleSubShift = (id) => {
    navigate(`/user/subshift/${id}`)
  }

  const handleConfirmDelete = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/employee/shifts/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOpenDeleteModal(false);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: "Position Deleted Successfully",
        life: 3000,
      });

      setTimeout(() => {
        navigate('/user/shifts/');
      }, 3000);
    } catch (error) {
      console.error('Error deleting position:', error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: "Failed to delete position",
        life: 3000,
      });
    }
  };

  const handleDeleteBreak = async (breakId) => {
    const token = localStorage.getItem("accessToken");
    try {
      await axios.delete(`https://hrmsbackend.prosevo.com/api/common/employee/breaks/${breakId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Break deleted successfully!', life: 3000 });
      // Optionally, refresh breaks list or update state
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error deleting break. Please try again.', life: 3000 });
      console.error("Error deleting break:", error);
    }
  };

  const handleDeleteClick = () => {
    if (shift.employee_count > 0) {
      toast.current.show({
        severity: 'warn',
        summary: 'Cannot Delete Shift',
        detail: "This shift has associated employees. Please reassign them before deleting.",
        life: 3000,
      });
    } else {
      setOpenDeleteModal(true); // Correctly opens the modal
    }
  };

  const transformEmployeeData = (employees) => {
    if (!employees || !Array.isArray(employees)) return [];
    return employees.map((employee, index) => ({
      id: employee.id,
      "Si No": index + 1,
      name: (
        <td className="d-flex gap-3 align-items-center px-0" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <img
            style={{ width: "25px", height: "25px", flexShrink: 0, borderRadius: '50%' }}
            src={employee.profile_photo ? employee.profile_photo : "/assets/user.png"}
            alt="user img"
          />
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{employee.name || "N/A"}</div>
        </td>
      ),
      branch: employee.branch?.branch_name || "N/A",
      department: employee.department?.dep_name || "N/A",
      contact_info: employee.phone_number || "N/A",
      Status: (
        <div style={{ color: employee.status ? 'green' : 'tomato' }}>
          <span
            style={{
              display: 'inline-block',
              width: '7px',
              height: '7px',
              borderRadius: '50%',
              backgroundColor: employee.status ? 'green' : 'tomato',
              marginRight: '5px',
            }}
          ></span>
          {employee.status ? 'Active' : 'Inactive'}
        </div>
      ),
    }));
  };

  const handleEditClick = () => {
    setShowEditModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const AddSubShift = () => {
    navigate('/user/subshiftadd')
  }

  const handleRowClick = (item) => {
    navigate(`/user/empdetails/${item.id}`);
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <>
      <style>
        {`
          @media (max-width: 500px) {
            .modalmainbox {
              min-width: 75% !important;
            }
          }
        `}
      </style>
      <div className="singlePositionContainer px-4 pt-4 w-100">
        <Toast ref={toast} position="bottom-right" />
        <h4>{shift?.shift_name} - {shift?.shift_type || "N/A"}</h4>
        <p className='mb-0 fw-bolder' style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>Working Hours: <span className='ms-2 fw-lighter' style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>{shift?.working_hours || "N/A"}</span></p>
        <p className='mb-0 fw-bolder' style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>Start Time: <span className='ms-2 fw-lighter' style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>{shift?.start_time}</span></p>
        <p className='mb-0 fw-bolder' style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>End Time: <span className='ms-2 fw-lighter' style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>{shift?.end_time || "N/A"}</span></p>
        <p className='mb-0 fw-bolder' style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>Cut off time: <span className='ms-2 fw-lighter' style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>{shift?.cut_off_time || "N/A"}</span></p>
        <p className='mb-0 fw-bolder' style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>Leave Type: <span className='ms-2 fw-lighter' style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>{shift?.leave_type || "N/A"}</span></p>
        <p className='mb-0 fw-bolder' style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>Total Employees: <span className='ms-2 fw-lighter' style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>{shift?.employee_count || "N/A"}</span></p>

        <div className="employeeTableContainer mt-4 w-100">
          <div className="d-flex justify-content-end mb-3 gap-2 gap-md-3">
            <button className='btn btn-outline-success'
              onClick={AddSubShift}
            >
              Add Sub-Shift  <IconPlus stroke={2} />
            </button>
            <>
              <button className='btn btn-outline-success' onClick={handleOpenBreakModal}>
                Add Break <IconPlus stroke={2} />
              </button>

              {showBreakModal && (
                <BreakModal
                  showModal={showBreakModal}
                  onClose={handleCloseBreakModal}
                  onSubmit={handleAddBreak}
                  initialBreak={selectedBreak}
                  isEditMode={isEditBreakMode}
                />
              )}
            </>

            <button
              className="btn btn-outline-primary"
              onClick={() => setShowEditModal(true)}
            >
              <IconEdit stroke={2} />
            </button>
            <button className="btn btn-outline-danger" onClick={handleDeleteClick}>
              <IconTrash stroke={2} />
            </button>
          </div>

          <ShiftEditModal
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
            shift={shift}
            shiftId={id}
            onUpdateSuccess={handleUpdateSuccess}
          />

          {breaks.length > 0 && (
            <>
              <BreaksAccordion
                breaks={breaks}
                onEditBreak={handleEditBreak}
                onDeleteBreak={handleDeleteBreak}
                header={"Breaks"}
              />
            </>
          )}

          {sub_shifts.length > 0 ? (
            <div className='mb-3 d-flex gap-2 flex-column'>
              <h6>Shift Lines</h6>
              {
                sub_shifts.map((item, index) => {
                  return (
                    <div
                      className="border border-2 rounded-3 py-1 px-2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => singleSubShift(item.shift_line.id)}
                    >
                      <p className='mb-0'>{item?.shift_line?.shift_name} - {item?.shift_line?.shift_type}</p>
                      <p>Start Time: {item?.shift_line?.start_time} - End time: {item?.shift_line?.end_time}</p>
                    </div>
                  )
                })
              }
            </div>
          ) : (
            ""
          )}

          {filteredEmployees.length > 0 ? (
            <Table
              header={header}
              product={transformEmployeeData(filteredEmployees)}
              onRowClick={handleRowClick}
              loading={loading}
            />
          ) : (
            <p>No employees found in this position.</p>
          )}
        </div>

        <BreakDeleteConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={handleConfirmDelete}
          breakName="Shift"
        />
      </div>
    </>
  )
}
