import React, { useState, useRef } from 'react';
import { IconTrash, IconEdit } from '@tabler/icons-react';
import { Modal, Box } from '@mui/material';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const BreaksAccordion = ({ breaks, onEditBreak, onDeleteBreak, header }) => {
    const [openIndex, setOpenIndex] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [breakToDelete, setBreakToDelete] = useState(null);
    const contentRefs = useRef([]); // Use refs to measure content height dynamically

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle current accordion, close others
    };

    const handleOpenDeleteModal = (breakItem) => {
        setBreakToDelete(breakItem);
        setDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setBreakToDelete(null);
        setDeleteModalOpen(false);
    };

    const handleConfirmDelete = () => {
        if (breakToDelete && onDeleteBreak) {
            onDeleteBreak(breakToDelete.id);
        }
        handleCloseDeleteModal();
    };

    return (
        <div className="d-flex flex-column gap-3 mb-3">
            <h5>{header}</h5>
            {breaks.map((breakItem, index) => {
                console.log(breakItem);
                return (
                    <div key={index} className="border border-2 rounded-3">
                        {/* Accordion Header */}
                        <div
                            className="px-3 py-2 d-flex justify-content-between align-items-center"
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleAccordion(index)}
                        >
                            <h6 className="mb-0">
                                {breakItem?.break_name|| "N/A"} - {breakItem?.break_type || 'N/A'}
                            </h6>
                        </div>

                        {/* Accordion Content with Animation */}
                        <div
                            ref={(el) => (contentRefs.current[index] = el)}
                            style={{
                                maxHeight: openIndex === index ? `${contentRefs.current[index]?.scrollHeight + 30}px` : '0px',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                            }}
                            className="d-flex justify-content-between"
                        >
                            <div className="px-3 py-2">
                                <p className="mb-0">Start time: {breakItem?.start_time || 'N/A'}</p>
                                <p className="mb-0">End time: {breakItem?.end_time || 'N/A'}</p>
                                <p className="mb-0">Duration: {breakItem.duration || 'N/A'}</p>
                            </div>
                            <div className="d-flex gap-2 flex-column justify-content-center pe-2">
                                {/* Delete Button */}
                                <button
                                    className="btn btn-outline-danger d-flex justify-content-center align-items-center"
                                    style={{ height: '30px' }}
                                    onClick={() => handleOpenDeleteModal(breakItem)}
                                >
                                    <IconTrash stroke={2} />
                                </button>
                                {/* Edit Button */}
                                <button
                                    className="btn btn-outline-primary d-flex justify-content-center align-items-center"
                                    style={{ height: '30px' }}
                                    onClick={() => onEditBreak(breakItem)} // Pass the breakItem to onEditBreak handler
                                >
                                    <IconEdit stroke={2} />
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }

            )}

            {/* Delete Confirmation Modal */}
            <Modal
                open={deleteModalOpen}
                onClose={handleCloseDeleteModal}
                aria-labelledby="break-delete-confirmation-modal"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '10px'
                }}>
                    <div className="w-100 d-flex align-items-center flex-column">
                        <WarningAmberIcon
                            sx={{
                                color: "#FFA000",
                                fontSize: 60,
                                width: "100%"
                            }}
                        />
                        <Box sx={{
                            fontWeight: "bold",
                            fontSize: "clamp(13px, 1.4vw, 17px)",
                            mb: 2
                        }}>
                            Confirm Deletion
                        </Box>
                        <Box sx={{
                            color: "#555",
                            mb: 2,
                            fontSize: "clamp(12px, 1.2vw, 14px)",
                            textAlign: "center",
                            paddingX: "10px"
                        }}>
                            Are you sure you want to delete "{breakToDelete?.break_name}"? This action cannot be undone.
                        </Box>
                    </div>
                    <div className="w-100 d-flex justify-content-between flex-row-reverse gap-3">
                        <button
                            className="btn btn-danger"
                            onClick={handleConfirmDelete}
                            style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
                        >
                            Delete
                        </button>
                        <button
                            className="btn btn-secondary"
                            onClick={handleCloseDeleteModal}
                            style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
                        >
                            Cancel
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default BreaksAccordion;
