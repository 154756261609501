import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '../ogComponent/table';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

export default function WorkingHours() {
    const [startDate, setStartDate] = useState(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const navigate = useNavigate();
    const token = localStorage.getItem('accessToken');

    const handleDateChange = (newDate, type) => {
        if (type === 'start') {
            setStartDate(newDate);
        } else if (type === 'end') {
            setEndDate(newDate);
        }
    };

    const fetchWorkingHours = async () => {
        setLoading(true);
        setError(null);
        
        try {
            if (!token) {
                throw new Error('Authentication token not found');
            }

            const response = await axios.get(
                'https://hrmsbackend.prosevo.com/api/attendance/employee-worked-hours-summary/',
                {
                    params: {
                        start_date: startDate.format('YYYY-MM-DD'),
                        end_date: endDate.format('YYYY-MM-DD'),
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Verify that response.data is an array
            if (!Array.isArray(response.data)) {
                throw new Error('Invalid data format received from server');
            }

            // Map API data to match the table structure
            const formattedData = response.data.map((item, index) => ({
                si_no: index + 1,
                employee_code: item.employee_code || 'N/A',
                id: item.id,
                name: (
                    <td
                      className="d-flex gap-3 align-items-center px-0"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <img
                        style={{
                          width: "25px",
                          height: "25px",
                          flexShrink: 0,
                          borderRadius: "50%",
                        }}
                        src={item.profile_photo || "/assets/user.png"}
                        alt="user img"
                      />
                      <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                        {item.name || "N/A"}
                      </div>
                    </td>
                  ),
                branch: item.branch?.branch_name || 'N/A',
                position: item.job_position?.job_title || 'N/A',
                working_hours: item.total_worked_hours || '0',
            }));

            setData(formattedData);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error.message || 'Failed to fetch working hours data');
            setData([]); // Reset data on error
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchWorkingHours();
    }, [startDate, endDate]);

    const handleUser = (item) => {
        if (item && item.id) {
            navigate(`/user/workinghours/${item.id}`);
        }
    };

    const header = [
        { name: 'si_no', label: '#', active: true },
        { name: 'employee_code', label: 'ID', active: true },
        { name: 'name', label: 'Name', active: true },
        { name: 'branch', label: 'Branch', active: true },
        { name: 'position', label: 'Position', active: true },
        { name: 'working_hours', label: 'Hours', active: true },
    ];

    if (error) {
        return (
            <div className="AttendanceContainer w-100 h-100 px-3 px-lg-4 pt-3">
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            </div>
        );
    }

    return (
        <div className="AttendanceContainer w-100 h-100 px-3 px-lg-4 pt-3">
            <h3 className="pt-2">Working Hours</h3>
            <div className="d-flex gap-3 py-3 justify-content-end">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Start Date"
                        className="MuidatePicker"
                        value={startDate}
                        onChange={(newValue) => handleDateChange(newValue, 'start')}
                        maxDate={endDate}
                    />
                    <DatePicker
                        label="End Date"
                        className="MuidatePicker"
                        value={endDate}
                        onChange={(newValue) => handleDateChange(newValue, 'end')}
                        minDate={startDate}
                    />
                </LocalizationProvider>
            </div>
            <Table 
                header={header} 
                product={data} 
                onRowClick={handleUser} 
                loading={loading}
            />
        </div>
    );
}