import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";

const Table = ({ header, product, onRowClick, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20;

  // Calculate the indices for the rows to display
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = product.slice(indexOfFirstRow, indexOfLastRow);

  // Calculate total pages
  const totalPages = Math.ceil(product.length / rowsPerPage);

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Filter headers to include only active columns
  const activeHeaders = header.filter((item) => item.active);

  return (
    <>
      <div className="tbConatiner w-100 d-flex flex-column justify-content-start align-items-start overflow-auto">
        <table style={{ width: "100%", height: "100%" }}>
          <thead
            className="position-sticky top-0 text-white z-2"
            style={{ backgroundColor: "#120e4d" }}
          >
            <tr style={{ fontSize: "clamp(12px, 1.3vw, 16px)" }}>
              {activeHeaders.map((item, index) => (
                <th key={index} style={{ fontWeight: "400" }}>
                  {item.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((item, index) => (
              <tr
                key={index}
                style={{
                  fontSize: "clamp(12px, 1.4vw, 16px)",
                  cursor: "pointer",
                }}
                onClick={() => onRowClick(item)}
              >
                {activeHeaders.map((headerItem, key) => (
                  <td key={key}>{item[headerItem.name]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination and Entries Info */}
      <div className="empFooter w-100 d-flex justify-content-center justify-content-sm-between align-items-center flex-wrap-reverse mt-3">
        <p
          style={{ minHeight: "50px", color: "grey" }}
          className="showText d-flex align-items-center mb-0"
        >
          {loading ? (
            <span className="placeholder-glow w-100">
              <span className="placeholder col-12"></span>
            </span>
          ) : (
            `Showing ${(currentPage - 1) * rowsPerPage + 1} to ${Math.min(
              currentPage * rowsPerPage,
              product.length
            )} of ${product.length} Entries`
          )}
        </p>
        {loading ? (
          <span
            className="placeholder-glow"
            style={{ width: "200px" }}
          >
            <span
              className="placeholder col-12"
              style={{ height: "32px" }}
            ></span>
          </span>
        ) : (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="small"
            className="mt-1 mt-sm-0"
          />
        )}
      </div>
    </>
  );
};

export default Table;
