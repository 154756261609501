import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./navbar";
import OffCanvas from "./offCanvas";
import Dashboard from "./dashboard";
import Employee from "../employee/employee";
import DetailsofEmp from "../employee/details";
import AddEmployee from "../employee/addEmployee";
import BackdropOffcanvas from "./BackdropOffcanvas"; 
import SelfLeaveTable from "../leaves/selfLeav";
import LeaveDetail from "../leaves/leaveDetail";
import LeaveShow from "../leaves/leaveShow";
import RequestView from "../leaves/leaveRequest";
import TodayLeaves from "../leaves/todayLeaves";
import LeaveReport from "../leaves/LeaveReport";
import { ProfilePage } from "../employee/profilepage";
import AddUser from "../admin/addUser";
import CreateUser from "../admin/createUser";
import AllAttendence from "../attendence/allAttendence";
import MyAttendence from "../attendence/myAttendence";
import EarlyLate from "../attendence/EarlyLate";
import "./container.css";
import  TabPanel from "../admin/functionalities";
import Branchs from "../admin/branchs";
import Departments from "../admin/Departments";
import JobPosition from "../admin/jobPosition";
import { useSelector } from "react-redux";
import BranchDetails from "../admin/branchDetails";
import DepartmentDetails from "../admin/depDetails";
import PositionDetails from "../admin/positionDetails";
import EditEmployee from "../employee/editEmployee";
import Shifts from "../admin/shifts";
import ShiftDetails from "../admin/shiftDetails";
import SalarySlip from "../employee/salarySlip";
import SingleSalarySlip from "../employee/singleSlip";
import Allowance from "../admin/allowance";
import CourseList from "../courses/courseList";
import CourseDetails from "../courses/courseDetails";
import Subjects from "../courses/subCourses";
import SubjectDetails from "../courses/subjectDetails";
import SingleTask from "../courses/singleTask";
import SingleDayFullPunch from "../attendence/dayFullPunch";
import SingleUserReport from "../leaves/singleUserReport";
import Subshift from "../admin/subShift";
import SubShiftAdd from "../admin/subShiftAdd";
import WorkingHours from "../attendence/workingHours";
import UserTime from "../attendence/userTime";
// import CmpIds from '..'

export default function FixedNavAndCanvas() {
  const [mainwidth, setMainWidth] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState("60px");
  const [opaacity, setOpacity] = useState("0%");
  const [hide, setHide] = useState("none");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isBelow1000px, setIsBelow1000px] = useState(window.innerWidth < 1000);

  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);

  const {emp_list, emp_count} = useSelector((state) => state.emp)
  console.log("emplyee count is :", emp_count);
  console.log("emplyee list is :", emp_list);

  useEffect(() => {
    const fetchData = () => {
      console.log('env is ',process.env.REACT_APP_SAMPLE);
      setTimeout(() => {
        setProduct(require("../jsonFiles/sidebar.json"));
        setLoading(false);
      }, 1000);
    };

    fetchData();
  }, []);
  
  useEffect(() => {
    const handleResize = () => {
      const width = document.documentElement.clientWidth;
      setWindowWidth(width);
      setIsBelow1000px(width < 1001);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log("windowWidth", windowWidth);

  const closeAllCollapses = () => {
    product.forEach((item) => {
      const collapseElement = document.getElementById(`flush-collapse${item.id}`);
      const buttonElement = document.querySelector(`[data-bs-target="#flush-collapse${item.id}"]`);
      if (collapseElement && buttonElement) {
        collapseElement.classList.remove("show");
        buttonElement.setAttribute("aria-expanded", "false");
      }
    });
  };

  const semiContainerStyle = {
    width: isBelow1000px
      ? "100vw"
      : mainwidth
      ? "calc(100vw - 250px)"
      : "calc(100vw - 60px)",
    marginTop: '59px' 
  };

  return (
    <div
      className="home_mainContainer  d-flex justify-content-end overflow-hidden"
      style={{ minHeight: "100vh", backgroundColor: "white" }}
    >
      <Navbar
        setMainWidth={setMainWidth}
        mainwidth={mainwidth}
        setCanvasWidth={setCanvasWidth}
        setOpacity={setOpacity}
        setHide={setHide}
        closeAllCollapses={closeAllCollapses}
        isBelow1000px={isBelow1000px} // Pass this prop to Navbar
      />
      {isBelow1000px ? (
        <BackdropOffcanvas product={product} />
      ) : (
        <OffCanvas
          mainwidth={mainwidth}
          setMainWidth={setMainWidth}
          opaacity={opaacity}
          setOpacity={setOpacity}
          canvasWidth={canvasWidth}
          setCanvasWidth={setCanvasWidth}
          hide={hide}
          setHide={setHide}
          product={product}
          loading={loading}
          closeAllCollapses={closeAllCollapses}
        />
      )}
      <div className="semi_container d-flex" style={semiContainerStyle}>
        <Routes>
          <Route path="home" element={<Dashboard />} />
          <Route path="Emp" element={<Employee />} />
          <Route path="EmpDetails/:id" element={<DetailsofEmp/>} />
          <Route path="addEmp" element={<AddEmployee />} />
          <Route path="editEmp/:emp_id" element={<EditEmployee />} />
          <Route path="selfLeave" element={<SelfLeaveTable />} />
          <Route path="leaveDetails/:empId/:id" element={<LeaveDetail />} />
          <Route path="leaveShow" element={<LeaveShow />} />
          <Route path="leaveRequest" element={<RequestView />} />
          <Route path="todayLeaves" element={<TodayLeaves />} />
          <Route path="leaveReport" element={<LeaveReport/>} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="userDetails" element={<AddUser />} />
          <Route path="createUser" element={<CreateUser />} />
          <Route path="attendence" element={<AllAttendence />} />
          <Route path="myattendence" element={<MyAttendence />} />
          <Route path="earlyLate" element={<EarlyLate />} />
          <Route path="functions" element={<TabPanel />} />
          <Route path="Branchs" element={<Branchs />} />
          <Route path="Branchs/:id" element={<BranchDetails />} />
          <Route path="deps" element={<Departments />} />
          <Route path="department/:id" element={<DepartmentDetails />} />
          <Route path="positions" element={<JobPosition />} />
          <Route path="positions/:id" element={<PositionDetails />} />
          <Route path="shifts" element={<Shifts />} />
          <Route path="shift/:id" element={<ShiftDetails />} />
          <Route path="salaryslip/:emp_id" element={<SalarySlip />} />
          <Route path="salaryslip/:emp_id/:id" element={<SingleSalarySlip />} />
          <Route path="allowance" element={<Allowance />} />
          <Route path="courses" element={<CourseList />} />
          <Route path="courses/:id" element={<CourseDetails />} />
          <Route path="subcourses" element={<Subjects />} />
          <Route path="subcourses/:id" element={<SubjectDetails />} />
          <Route path="task/:id" element={<SingleTask />} />
          <Route path="dayList/:userId" element={<SingleDayFullPunch />} />
          <Route path="leaveReport/:id" element={<SingleUserReport />} />
          <Route path="subshift/:id" element={<Subshift />} />
          <Route path="subshiftadd" element={<SubShiftAdd />} />
          <Route path="subshiftadd/:id" element={<SubShiftAdd />} />
          <Route path="workinghours" element={<WorkingHours />} />
          <Route path="workinghours/:id" element={<UserTime />} />
        </Routes>
      </div>
    </div>
  );
}
